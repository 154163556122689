import React from 'react';
import { PopupWidget } from 'react-calendly';

const PopupComponent = () => {
  return (
    <div className="popup-widget">
      <PopupWidget
        url="https://calendly.com/englandfootballacademy/efa-academy-session" 
        rootElement={document.getElementById('root')}
        text="Book a Session"
        borderRadius="0%"
        textColor="#ffffff"
        color="#3953a3"
      />
    </div>
  );
};

export default PopupComponent;
