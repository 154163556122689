import React, { useState } from 'react';
import background from "./landing-page-BG.png";
import {
  ChakraProvider,
  theme,
  Flex,
  Input,
  Button,
  Box,
  Stack,
  Heading,
} from '@chakra-ui/react';
import jsonp from 'jsonp';
import PopupComponent from './popUp'

function App() {
  const [email, setEmail] = useState('');
  const [number, setAge] = useState('');
  const [text, setClub] = useState('');
  const [loading, setLoading] = useState(false);

  const onSubmit = e => {
    e.preventDefault();
    setLoading(true);
    const url = 'https://gmail.us17.list-manage.com/subscribe/post-json?u=96bc94ceec9abd0eaa4b0084d&amp;id=8e88bd4128&amp;f_id=003f5be0f0'; // you can use .env file to replace this
    jsonp(`${url}&EMAIL=${email}&AGE=${number}&CLUB=${text}`, { param: 'c' }, (_, { msg }) => {
      alert(msg);
      setLoading(false);
    });
  };

  return (
    <div style={{ backgroundImage: `url(${background})`, 
    position:'fixed',
    minWidth:'100%', 
    minHeight:'100%',
    backgroundSize:'cover', 
    backgroundPosition:'center'

 }}>
    <ChakraProvider theme={theme} >
      <Box style={{
        padding:"75px"
      }}>
        <Flex
          as="form"
          mx="auto"
          height="100vh"
          maxWidth="350px"
          onSubmit={onSubmit}
          direction="column"
          justifyContent="center"
        >
          <Stack spacing={3}>
          <Input
            value={email}
            placeholder="Enter your email"
            _placeholder={{ opacity: 0.5, color: 'black' }}
            onChange={e => setEmail(e.target.value)}
          />
            <Input
            value={number}
            placeholder="Players Age?"
            _placeholder={{ opacity: 0.5, color: 'black' }}
            onChange={e => setAge(e.target.value)}
          />

          <Input
            value={text}
            placeholder="Currently Plays Club Soccer?"
            _placeholder={{ opacity: 0.5, color: 'black' }}
            onChange={e => setClub(e.target.value)}
          />
          </Stack>
          <Button
            mt="2"
            bg="#f2b500"
            type="submit"
            color="white"
            isLoading={loading}
            _hover={{ bg: '#0000ff' }}
            _active={{ bg: 'gray.600' }}
          >
            Register
          </Button>
          

        </Flex>

        
      </Box>

      

    </ChakraProvider>
   <PopupComponent></PopupComponent>
    
    </div>
  );
}

export default App;
